import React from 'react';
import { bool, func, string } from 'prop-types';

import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';

import Confirmation from '../Confirmation/Lazy';

function ModalConfirmation({ lang, isOpen, onClose }) {
  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent maxWidth="500px">
        <ModalBody padding="0">
          <Confirmation onBack={onClose} lang={lang} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

ModalConfirmation.propTypes = {
  isOpen: bool.isRequired,
  lang: string.isRequired,
  onClose: func.isRequired,
};

export default ModalConfirmation;
